import React from 'react';

import Image1 from '../../assets/images/clients/daneshmand-logo.jpg';
import Image2 from '../../assets/images/clients/naft-logo.jpg';
import Image3 from '../../assets/images/clients/khorshidnet-logo.jpg';
import Image4 from '../../assets/images/clients/gostaran-logo.jpg';

class Clients extends React.Component {
    render() {
        return (
            <section className="clients-wrapper wrapper">
                <div className="container-fluid">
                    <div className="wrapper-title">
                        <span className="heading-meta">مجموعه هایی که تجربه همکاری با آنها را داشتم</span>
                        <h3>برخی از مشتریان</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <img src={Image1} alt="گروه تولیدی و بازرگانی شیمیایی دانشمند" title="گروه تولیدی و بازرگانی شیمیایی دانشمند" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <img src={Image2} alt="نفت خوزستان (پتروشاپ)" title="نفت خوزستان (پتروشاپ)" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <img src={Image3} alt="اینترنت خورشیدنت" title="اینترنت خورشیدنت" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="text-center">
                                <img src={Image4} alt="گستران قالب های صنعتی" title="گستران قالب های صنعتی" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Clients;
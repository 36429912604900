import React from 'react';

import Image1 from '../../assets/images/resume.jpg';

class About extends React.Component {
    render() {
        return (
            <section className="about-wrapper wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-7">
                            <img src={Image1} alt="نوید بهروزی مجد" title="نوید بهروزی مجد" />
                        </div>
                        <div className="col-lg-5">
                            <div className="wrapper-title">
                                <span className="heading-meta">رزومه کاری من</span>
                                <h1>نوید بهروزی مجد</h1>
                            </div>
                            <p>خوب جریان از اونجا شروع میشه که سال 93 یه وبلاگ داشتم که توش محتوا میزاشتم... یه روز با خودم گفتم که چقدر خوب میشد اگر قالب وبلاگم رو به جای اینکه کپی کنم، خودم طراحی کنم. خیلی اتفاقی با یه موسسه آموزش طراحی سایت آشنا شدم که رفتم اونجا و دوره ی برنامه نویسی وبسایت که توش asp.net, sql, html, css, jquery و... رو داشت.</p>
                            {/* <p>بعدش دیدم برنامه نویسی واسه خودش دنیاییه و فقط صرفا طراحی قالب وبلاگ نیست که از اون موقع استارت یادگیری من خورد</p> */}
                            <div className="cardvisit">
                                <p className="title">نوید بهروزی مجد</p>
                                <span>توسعه دهنده وب، کارشناس سئو</span>
                                <div className="line"></div>
                                <p>
                                    <i className="fas fa-phone"></i>
                                    <a href="tel:+989397967117" rel="noopener noreferrer" className="ltr-text">0939 796 71 17</a>
                                </p>
                                <p>
                                    <i className="fas fa-envelope"></i>
                                    <a href="mailto:navid.behroozimajd78@gmail.com" rel="noopener noreferrer" className="ltr-text">navid.behroozimajd78@gmail.com</a>
                                </p>
                                <p>
                                    <i className="fab fa-instagram"></i>
                                    <a href="https://www.instagram.com/navid_behroozi_" target="_blank" rel="noopener noreferrer external" className="ltr-text">navid_behroozi_</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About;
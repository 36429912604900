import React from 'react';

class Services extends React.Component {
    render() {
        return (
            <section className="services-wrapper wrapper">
                <div className="container-fluid">
                    <div className="wrapper-title">
                        <span className="heading-meta">خدمات</span>
                        <h3>توانمندی های من</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item text-center">
                                <i className="fab fa-html5"></i>
                                <h2>طراحی سایت</h2>
                                <p>طراحی انواع وبسایت های شرکتی که معمولا شامل سایت های شرکت های خدمات محور یا محصول محور میشوند</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item text-center">
                                <i className="fas fa-bullhorn"></i>
                                <h2>سئو و بهینه سازی</h2>
                                <p>کوتاه ترین توضیحی که میتونیم درباره سئو بدیم، یعنی شما به یک کلمه ایی بخواهید بیایید صفحه اول گوگل.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item text-center">
                                <i className="fas fa-shopping-cart"></i>
                                <h2>فروشگاه اینترنتی</h2>
                                <p>خلاصه ی کلام به سایت هایی که شما توش بتونید محصولات و خدمات خودتون رو اینترنتی به فروش برسونین، فروشگاه اینترنتی میگن.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item text-center">
                                <i className="fas fa-gem"></i>
                                <h2>وب اپلیکیشن</h2>
                                <p>وب اپلیکیشن ها، سایت های نسل جدید هستن که سرعت بسیار فوق العاده ای دارن. سایت بنده هم وب اپلیکیشن هست.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item text-center">
                                <i className="fas fa-user-tie"></i>
                                <h2>مشاوره</h2>
                                <p>مشاوره در زمینه کسب و کار شما که چطور میتونید توی فضای دیجیتال پیشرفت کرده و درآمد بهتری داشته باشید</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item text-center">
                                <i className="fas fa-file-image"></i>
                                <h2>کاتالوگ دیجیتال</h2>
                                <p>در بعضی شرایط نیازی به سایت نیست و صرفا کاتالوگ دیجیتال میتونه برای کارفرما مناسب باشه که هزینه چاپ هم براتون نداره :)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;
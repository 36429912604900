import React from 'react';
import { Link } from 'gatsby';

import Cover1 from '../../assets/images/portfolio/petroshop-cover.jpg';
import Complete1 from '../../assets/images/portfolio/petroshop-complete.jpg';
//
import Cover2 from '../../assets/images/portfolio/khorshidnet-cover.jpg';
import Complete2 from '../../assets/images/portfolio/khorshidnet-complete.jpg';
//
import Cover3 from '../../assets/images/portfolio/eliya-cover.jpg';
import Complete3 from '../../assets/images/portfolio/eliya-complete.jpg';
//
import Cover4 from '../../assets/images/portfolio/chemical-cover.jpg';
import Complete4 from '../../assets/images/portfolio/chemical-complete.jpg';

class Portfolio extends React.Component {
    render() {
        return (
            <section className="portfolio-wrapper wrapper">
                <div className="container-fluid">
                    <div className="wrapper-title">
                        <span className="heading-meta">نمونه کارها</span>
                        <h3>برخی از نمونه کارها</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <figure>
                                <img src={Cover1} alt="شرکت نفت خوزستان (پتروشاپ)" title="شرکت نفت خوزستان (پتروشاپ)" />
                                <figcaption>
                                    <a href={Complete1} target='_blank' rel="noopener noreferrer">
                                        <h5>نفت خوزستان (پتروشاپ)</h5>
                                        <p>سایت دایرکتوری در زمینه نفت و حفاری</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-6">
                            <figure>
                                <img src={Cover2} alt="اینترنت خورشیدنت" title="اینترنت خورشیدنت" />
                                <figcaption>
                                    <a href={Complete2} target='_blank' rel="noopener noreferrer">
                                        <h5>اینترنت خورشیدنت</h5>
                                        <p>سایت شرکتی + پشتیبانی شبکه های اجتماعی</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-6">
                            <figure>
                                <img src={Cover3} alt="ثبت شرکت ایلیا" title="ثبت شرکت ایلیا" />
                                <figcaption>
                                    <a href={Complete3} target='_blank' rel="noopener noreferrer">
                                        <h5>ثبت شرکت ایلیا</h5>
                                        <p>سایت شرکتی سئو محور</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-6">
                            <figure>
                                <img src={Cover4} alt="پتروشیمی دانشمند" title="پتروشیمی دانشمند" />
                                <figcaption>
                                    <a href={Complete4} target='_blank' rel="noopener noreferrer">
                                        <h5>پتروشیمی دانشمند</h5>
                                        <p>سایت شرکتی ۴ زبانه + کاتالوگ دیجیتال</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <Link to="/portfolio" className="btn btn-primary">نمایش همه نمونه کارها</Link>
                </div>
            </section>
        );
    }
}

export default Portfolio;
import React from "react"

import SEO from "../components/Seo"
import Layout from "../components/Layout"

import About from "../components/home/About"
import Skills from "../components/home/Skills"
import Services from "../components/home/Services"
import Portfolio from "../components/home/Portfolio"
import Clients from "../components/home/Clients"

const HomePage = () => {
  return (
    <Layout>
      <SEO
        title="توسعه دهنده و طراح سایت"
        description="نوید بهروزی هستم، نزدیک به 5 سال هست که در زمینه توسعه و طراحی سایت، سئو و بهینه سازی، تولید انواع وب اپلیکیشن فعالیت داشته و زبان های html, css javascript تخصص اصلی من هستند"
      />

      <About />
      <Skills />
      <Services />
      <Portfolio />
      <Clients />
    </Layout>
  )
}

export default HomePage